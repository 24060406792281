<template>
  <HeaderView/>
  <router-view/>
</template>

<script>
  import HeaderView from "@/components/includes/HeaderView";
  export default {
    name: "App",
    components:{HeaderView}
  }
</script>

<style>
nav a.router-link-exact-active {
  color: #42b983;
}
</style>
