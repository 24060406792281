import axiosInstance from "@/plugins/HTTP";
import authHeader from "./auth-header";

class AuthService {
  login(user) {
    //alert("login service");
    return axiosInstance
      .post("login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.status) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  resellerLogin(user) {
    return axiosInstance
      .post("reseller-login", {
        login: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.status) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return axiosInstance
      .post("register", {
        enable_email_verification: 1,
        username: user.username,
        contact_email: user.contact_email,
        password: user.password,
        password_confirmation: user.password_confirmation,
        country: "BD",
      })
      .then((response) => {
        // if (response.status) {
        //   localStorage.setItem("user", JSON.stringify(response.data));
        // }
        return response.data;
      });
  }
  resellerRegister(user) {
    console.log('user data', user);
    return axiosInstance
      .post("reseller-register", {
        enable_email_verification: 1,
        login: user.login,
        display_name: user.login,
        phone: user.phone,
        email: user.login,
        full_name: user.full_name,
        password: user.password,
      })
      .then((response) => {
        // if (response.status) {
        //   localStorage.setItem("user", JSON.stringify(response.data));
        // }
        return response.data;
      });
  }
  otpVerification(user) {
    return axiosInstance.post("token-verification", user).then((response) => {
      return response.data;
    });
  }

  forgetotpVerification(user) {
    return axiosInstance.post("forget-password-token-verify", user).then((response) => {
      return response.data;
    });
  }

  resellerOtpVerification(user) {
    return axiosInstance.post("reseller-token-verify", user).then((response) => {
      return response.data;
    });
  }


  otpResend(user) {
    return axiosInstance.post("otp-resend", user).then((response) => {
      return response.data;
    });
  }

  changePassword(user) {
    return axiosInstance.post(
      "change-forget-password",
      {
        username: user.username,
        token: user.token,
        password: user.password,
        password_confirmation: user.password_confirmation
      },
      {
        headers: authHeader(),
      }
    );
  }

  forgotPassword(user) {
    return axiosInstance.post("reset-password-token-request", user, {
    });
  }

  resetPassword(user, id, token) {
    return axiosInstance.post("reset-password", {
      id: id,
      token: token,
      password: user.password,
      password_confirmation: user.password_confirmation,
    });
  }
}

export default new AuthService();
