import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/account-login",
    name: "accountLogin",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AccountLoginView"),
    meta: {
      title: "Account Login",
    },
  },
  {
    path: "/reseller-login",
    name: "reseller",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ResellerLoginView"),
    meta: {
      title: "Reseller Login",
    },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignUpView"),
    meta: {
      title: "Sign Up",
    },
  },

  {
    path: "/sign-up-reseller",
    name: "signupreseller",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/ResellerSignUpView"),
    meta: {
      title: "Reseller Sign Up",
    },
  },

  {
    path: "/otp/verification",
    name: "otpverification",
    component: () =>
      import(/* webpackChunkName: "otpverification" */ "../views/OtpVerify"),
    meta: {
      title: "OTP Verification",
    },
  },
  {
    path: "/create/payment/:hueyiPayResponses",
    name: "HueyiPayResponse",
    component: () =>
      import(/* webpackChunkName: "otpverification" */ "../views/CreatePayment"),
    meta: {
      title: "Hueyipay Payment",
    },
  },
  {
    path: "/reseller-otp-verification",
    name: "resellerverification",
    component: () =>
      import(
        /* webpackChunkName: "otpverification" */ "../views/ResellerOtpVerify"
      ),
    meta: {
      title: "Reseller OTP Verification",
    },
  },

  {
    path: "/password-otp-verification",
    name: "passwordotpverification",
    component: () =>
      import(
        /* webpackChunkName: "otpverification" */ "../views/PasswordOtpVerify.vue"
      ),
    meta: {
      title: "Password OTP Verification",
    },
  },
  {
    path: "/forget-password",
    name: "forget",
    component: () =>
      import(/* webpackChunkName: "forget" */ "../views/ForgetPasswordView"),
    meta: {
      title: "Forget Password",
    },
  },
  {
    path: "/change-password",
    name: "changepassword",
    component: () =>
      import(/* webpackChunkName: "forget" */ "../views/ChangePasswordView"),
    meta: {
      title: "Channge Password",
    },
  },

  {
    path: "/save-new-password",
    name: "savepassword",
    component: () =>
      import(
        /* webpackChunkName: "forget" */ "../views/SavePasswordSuccessView"
      ),
    meta: {
      title: "Channge Password",
    },
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView"),
    meta: {
      middleware: "guest",
      title: "Dashboard",
    },
  },
  {
    path: "/dashboard-reseller",
    name: "dashboardReseller",
    component: () =>
      import(
        /* webpackChunkName: "resellerdashboard" */ "../views/ResellerDashboardView"
      ),
    meta: {
      middleware: "guest",
      title: "Reseller Dashboard",
    },
  },
  {
    path: "/account-profile",
    name: "accountProfile",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/AccountProfileView"),
    meta: {
      middleware: "guest",
      title: "Account Profile",
    },
  },
  {
    name: "PurchaseSuccess",
    path: "/purchase/success",
    query: {},
    component: () => import("../views/PurchaseSuccessView"),
    meta: {
      middleware: "guest",
      title: "Purchase Success",
    },
  },
  {
    name: "PurchaseCancel",
    path: "/purchase/cancel",
    query: {},
    component: () => import("../views/PurchaseCancelView"),
    meta: {
      middleware: "guest",
      title: "Purchase Cancel",
    },
  },
  {
    name: "PurchaseFailed",
    path: "/purchase/failed",
    query: {},
    component: () => import("../views/PurchaseFailedView"),
    meta: {
      middleware: "guest",
      title: "Purchase Failed",
    },
  },
  {
    name: "ResellerPaySuccess",
    path: "/purchase/success/reseller",
    query: {},
    component: () => import("../views/ResellerPurchaseSuccessView"),
    meta: {
      middleware: "guest",
      title: "Purchase Success",
    },
  },
  {
    name: "ResellerPayCancel",
    path: "/purchase/cancel/reseller",
    query: {},
    component: () => import("../views/ResellerPurchaseCancelView"),
    meta: {
      middleware: "guest",
      title: "Purchase Cancel",
    },
  },
  {
    name: "ResellerPayFailed",
    path: "/purchase/failed/reseller",
    query: {},
    component: () => import("../views/ResellerPurchaseFailedView"),
    meta: {
      middleware: "guest",
      title: "Purchase Failed",
    },
  },
];

const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  let keepSignedIn = store.state.keepSignedIn.keep_signed_in;
  let auth = store.state.auth.status.loggedIn;

  if (to.meta.middleware === "guest") {
    if (auth) {
      next();
    } else {
      next({ name: "login", query: { from: to.path } });
    }
  } else {
    if (auth) {
      if (!keepSignedIn) {
        if (!keepSignedIn && auth) {
          store.dispatch("auth/logout");
          next({ name: "login", query: { from: to.path } });
        }
      }
      if (to.name == "home" || to.name == "login") {
        next({ name: "dashboard" });
      }

      if (to.name == "accountLogin") {
        next({ name: "accountProfile" });
      }
      

      next();
    } else {
      next();
    }
  }
});

export default router;
